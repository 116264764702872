import React from 'react';
import PropTypes from 'prop-types';

import { BasicIcon } from '@nuage/ui-components';

import './stepsOnboarding.scss';

const StepsOnboarding = ({ steps, actualStep, loading }) => (
    <ul className={`steps-onboarding ${loading ? 'steps-onboarding--loading' : ''}`.trim()}>
        { steps.map((step, index) => (
            <li
                className={`
                steps-onboarding__elt
                ${index < actualStep ? 'steps-onboarding__elt--passed' : ''}
                ${index === actualStep ? 'steps-onboarding__elt--active' : ''}`
                    .replace(/\s+/g, ' ')
                    .trim()}
                key={step}
            >
                <span className="steps-onboarding__number">
                    <span className="steps-onboarding__number__data">
                        {index + 1}
                    </span>
                    <BasicIcon size="small" name="ico-check" className="steps-onboarding__number__icon" />
                </span>
                <span className="steps-onboarding__text">{step}</span>
                {(index < steps.length - 1) && (
                    <BasicIcon name="ico-arrow" className="steps-onboarding__arrows" />
                )}
            </li>
        ))}
    </ul>
);

StepsOnboarding.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string),
    actualStep: PropTypes.number,
    loading: PropTypes.bool,
};

StepsOnboarding.defaultProps = {
    steps: [],
    actualStep: null,
    loading: true,
};

export default StepsOnboarding;
