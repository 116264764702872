import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ComplexButton, StateIcon } from '@nuage/ui-components';

import { useForm } from 'react-hook-form';

import { useDidomiContext } from '@organisms/contexts/didomiContext';
import { dispatchGtagEvent, dispatchLinkedInEvent, dispatchTwitterEvent } from '@utils/eventsManager';

// Molecules
import ErrorWindow from '@molecules/interactive/errorWindow';
import StepsOnboarding from '@molecules/visual/stepsOnboarding';

// Organisms
import MainLayout from '@organisms/main/mainLayout';

// Form parts
import OnboardingAccount from '@organisms/forms/onboardingAccount';
import OnboardingCustomer from '@organisms/forms/onboardingCustomer';
import OnboardingContract from '@organisms/forms/onboardingContract';
import OnboardingPayment from '@organisms/forms/onboardingPayment';
import OnboardingOrganization from '@organisms/forms/onboardingOrganization';

// Validate Step
import OnboardingValidate from '@organisms/forms/onboardingValidate';

// Error Screen
import OnboardingError from '@molecules/group/onboardingError';

import LogoIconC from '@images/svg/logo-color.svg';

import lang from '@utils/lang';
import NuageClient from '@utils/client';

import { slugify, getCookie } from '../../components/helpers';

import './onboarding.scss';

const OnBoardingPage = ({ params }) => {
    const { _string } = lang;
    const { adwordsActivated, hubspotActivated } = useDidomiContext();

    if (params.id === '[id]') { navigate('/'); }

    // This requires CSP policy to be set correctly.
    const elFonts = [
        {
            // WARN : Whatch out, this isn't a variable
            src: 'url("/fonts/Roboto-Regular.woff2") format("woff2")',
            family: 'Roboto',
            style: 'normal',
        },
    ];

    // Call for every steps --------
    // Step 0
    const {
        handleSubmit: accountSubmit,
        control: accountControl,
        setValue: setNewsletter,
        formState: { errors: accountErrors },
    } = useForm({
        mode: 'onTouched',
    });
    // Step 1
    const {
        handleSubmit: customerSubmit,
        control: customerControl,
        formState: { errors: customerErrors },
    } = useForm({
        mode: 'onTouched',
    });
    // Step 2
    const {
        handleSubmit: contractSubmit,
        control: contractControl,
        setValue: setContractValue,
        formState: { errors: contractErrors },
    } = useForm({
        mode: 'onChange',
    });
    // Step 3
    const {
        handleSubmit: paymentSubmit,
        control: paymentControl,
        setValue: setPaymentValue,
        // trigger: paymentTrigger,
        formState: { errors: paymentErrors },
    } = useForm({
        mode: 'onTouched',
    });
    // Step 4
    const {
        handleSubmit: organizationSubmit,
        control: organizationControl,
        setValue: setOrganizationValue,
        setError: setOrganizationError,
        clearErrors: clearOrganizationErrors,
        trigger: organizationTrigger,
        formState: { errors: organizationErrors },
    } = useForm({
        mode: 'onTouched',
    });
    // -----------------------------

    const [clObj, setClObj] = useState(null);

    const [loading, setLoading] = useState(true);
    const [contract, setContract] = useState(null);

    const [actualStep, setActualStep] = useState(-1);

    const [userProfile, setUserProfile] = useState(null);
    const [globalErrorType, setGlobalErrorType] = useState(null);

    const [generatedOrgSlug, setGeneratedOrgSlug] = useState(null);
    const [isCheckOrgaSlugLoading, setIsCheckOrgaSlugLoading] = useState(true);

    const [stripePromise, setStripePromise] = useState(null);
    const [skippedPayment, setSkipPayment] = useState(false);
    const [paymentDone, setPaymentDone] = useState(null);

    const [isOpenErrorWindow, setErrorWindow] = useState(false);
    const [textErrorWindow, setTextErrorWindow] = useState('');

    const [typePayment, setTypePayment] = useState('cb');

    const [coupon, setCoupon] = useState(null);
    const [sponsor, setSponsor] = useState(null);

    // Vars for react hook (dont work with state react values)
    let errorsLogged = false;
    let actualSubmit = accountSubmit;

    // Stripe handling
    const [stripeError, setStripeError] = useState(true);
    const stripeElement = useRef({}, []);

    const [steps] = useState(_string('onboarding_steps'));

    // Used to send events when the user completes the onboarding.
    // Keep track to only send events once,
    // as the final confirmation can come from 2 different sources.
    const hasSentConversionEvents = useRef(false);
    const isWaitingForConfirmation = useRef(false);

    // Used to send events when the user completes the onboarding.
    const sendConversionEvents = useCallback(
        () => {
            if (
                hasSentConversionEvents.current
                || !isWaitingForConfirmation.current
                || !userProfile
            ) {
                return;
            }

            let typeSignUp = '';
            const couponObj = userProfile.extensions.find((element) => element.name === 'coupon');
            if (couponObj && couponObj.data.name !== process.env.COUPON_DEFAULT) { typeSignUp = 'coupon'; }
            const sponsorObj = userProfile.extensions.find((element) => element.name === 'sponsor');
            if (sponsorObj) { typeSignUp = 'parrainage'; }

            // Events always sent when we have a signup confirmation from back-end.
            dispatchGtagEvent('sign_up', {
                method: typeSignUp,
            });

            if (adwordsActivated) {
                dispatchGtagEvent('conversion', {
                    send_to: 'AW-1068648814/e9KRCKqKo_8CEO6Syf0D',
                });
            }

            dispatchLinkedInEvent('track', { conversion_id: 6282626 });

            dispatchTwitterEvent('o8dcl');

            // Events sent ONLY when the user added a payment method.
            if (!skippedPayment) {
                dispatchGtagEvent('add_payment_info', {
                    payment_type: 'carte bancaire',
                    coupon: couponObj && couponObj.data.name !== process.env.COUPON_DEFAULT ? couponObj.data.name : '',
                });

                if (adwordsActivated) {
                    dispatchGtagEvent('conversion', {
                        send_to: 'AW-1068648814/DMSrCJmCo_8CEO6Syf0D',
                    });
                }

                dispatchLinkedInEvent('track', { conversion_id: 6282634 });

                dispatchTwitterEvent('o8dcq');
            }

            // Mark as "sent" so that it won't retrigger anymore.
            hasSentConversionEvents.current = true;
            isWaitingForConfirmation.current = false;
        },
        [adwordsActivated, skippedPayment, userProfile],
    );

    // Switching to errors and submit
    if (
        accountErrors
        && customerErrors
        && contractErrors
        && paymentErrors
        && organizationErrors
    ) {
        if (
            Object.keys(accountErrors).length !== 0
            || Object.keys(customerErrors).length !== 0
            || Object.keys(contractErrors).length !== 0
            || (actualStep === 3 && Object.keys(paymentErrors).length !== 0)
            || (actualStep === 4 && Object.keys(organizationErrors).length !== 0)
        ) {
            errorsLogged = true;
        } else {
            errorsLogged = false;
        }
    }

    if (actualStep) {
        switch (actualStep) {
        case 0:
            actualSubmit = accountSubmit;
            break;
        case 1:
            actualSubmit = customerSubmit;
            break;
        case 2:
            actualSubmit = contractSubmit;
            break;
        case 3:
            actualSubmit = paymentSubmit;
            break;
        case 4:
            actualSubmit = organizationSubmit;
            break;
        default:
            actualSubmit = accountSubmit;
            break;
        }
    }

    /**
     * Try to guess the current step of the onboarding from its data
     */
    const guessStepFromOnboardingData = useCallback((sentOnboarding) => {
        const {
            // Step 0
            password,
            // Step 1
            organizationCity,
            // Step 2
            contractSigned,
            // Step 4
            organizationName,
            // Step 5
            status,
        } = sentOnboarding;

        const isPaymentDone = paymentDone || skippedPayment;

        let step = -1;
        if (!['progress', 'ready', 'building', 'completed'].includes(status)) {
            return 'preFlight';
        }

        if (!password) {
            step = 0;
        } else if (!organizationCity) {
            step = 1;
        } else if (!contractSigned) {
            step = 2;
        } else if (!isPaymentDone && !['ready', 'building', 'completed'].includes(status)) {
            step = 3;
        } else if (!organizationName || ['ready', 'building'].includes(status)) {
            step = 4;
        } else if (status === 'completed') {
            step = 5;
        }

        return step;
    }, [paymentDone, skippedPayment]);

    const curateFormData = useCallback((ressource, step) => {
        switch (step) {
        case 0:
            return {
                firstname: ressource.firstname,
                lastname: ressource.lastname,
                password: ressource.password,
                newsletter: ressource.newsletter,
            };
        case 1:
            return {
                organizationBillingDescription: ressource['customer-description'],
                organizationAddress: `${ressource['customer-addr-line-1']}${ressource['customer-addr-line-2'] ? `\n${ressource['customer-addr-line-2']}` : ''}`,
                organizationCity: ressource['customer-city'],
                organizationCountry: ressource['customer-country'].value,
                organizationVAT: ressource['customer-vat'],
                organizationZipcode: ressource['customer-zip-code'],
            };
        case 2:
            return {
                contractSigned: ressource['customer-contract'],
            };
        case 3:
            return {
                paymentMethod: { isTbd: typePayment === 'cb' },
            };
        case 4:
            return {
                organizationDescription: ressource['customer-description'],
                organizationName: ressource['customer-slug'],
            };
        default:
            return {};
        }
    }, [typePayment]);

    const handleGlobalErrors = useCallback(() => {
        setTextErrorWindow(_string('error_window_global'));
        setErrorWindow(true);
    }, [_string]);

    const handleData = useCallback((error, data) => {
        if (error) {
            if (error.status === 404) {
                setGlobalErrorType('404');
            } else {
                setGlobalErrorType('global');
            }
            setUserProfile(null);
            setLoading(false);
        } else {
            const guessedStep = guessStepFromOnboardingData(data);

            // Preflight check to identify the opening of the onboarding
            if (guessedStep === 'preFlight') {
                const userToSend = data;
                userToSend.status = 'progress';
                userToSend.flush().onReady((errorUser, dataUser) => {
                    if (error) {
                        handleGlobalErrors();
                    } else {
                        handleData(errorUser, dataUser);
                    }
                });
            } else {
                // No email in element, it's an error
                if (guessedStep < 0) {
                    setUserProfile(null);
                } else {
                    setActualStep(guessedStep);
                    setUserProfile(data);
                }

                const couponObj = data.extensions.find((element) => element.name === 'coupon');
                if (couponObj) {
                    setCoupon(couponObj.data);
                }

                const sponsorObj = data.extensions.find((element) => element.name === 'sponsor');
                if (sponsorObj) {
                    setSponsor(sponsorObj.data);
                }
            }

            if (guessedStep >= 0 && !['sent', 'ready', 'building'].includes(data.status)) {
                setLoading(false);
            }

            // We send the conversion events
            // (only the the required conditions inside the func. are fulfilled)
            if (guessedStep === 5) {
                sendConversionEvents();
            }
        }
    }, [guessStepFromOnboardingData, handleGlobalErrors, sendConversionEvents]);

    const saveCardToStripeCustomer = async (
        stripeSetupIntent,
        cardholderName,
        stripe,
        elements,
        cardElement,
        usrData,
    ) => {
        const setupIntentClientSecret = stripeSetupIntent.data.client_secret;

        // Use your card Element with other Stripe.js APIs
        const { setupIntent, error } = await stripe.confirmCardSetup(setupIntentClientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: cardholderName,
                    email: usrData.mail,
                    address: usrData.address,
                },

            },
        });

        if (error) { throw error; }

        return setupIntent;
    };

    /**
     * Get Stripe <Elements> from child
     */
    const getStripeElement = (elt) => {
        stripeElement.current = elt;
    };

    const checkIfOrgaSlugExists = useCallback((name, cb) => {
        setIsCheckOrgaSlugLoading(true);

        clObj.checkOrganization({ name }).onReady((error) => {
            setIsCheckOrgaSlugLoading(false);
            cb(!error);
        });
    }, [clObj]);

    // TODO : Nuke this
    // Help, I've been forced into coding this awful function
    // I live under stairs and eat dirt
    // Also, need to clean this
    // Sincerely, Harry Potter
    const ultraTemporaryDirtyAndNotAcceptableFunction = useCallback((slug) => {
        setTimeout(() => {
            // First try
            checkIfOrgaSlugExists(slug, (isFirstExist) => {
                if (isFirstExist) {
                    setUserProfile((previous) => {
                        const newStatus = {
                            ...previous,
                            status: 'completed',
                        };
                        return newStatus;
                    });
                    // just in case of mercure event not getting received.
                    // Should not be triggered more than once.
                    sendConversionEvents();
                } else {
                    setTimeout(() => {
                        // Second try
                        checkIfOrgaSlugExists(slug, (isSecondExist) => {
                            if (isSecondExist) {
                                setUserProfile((previous) => {
                                    const newStatus = {
                                        ...previous,
                                        status: 'completed',
                                    };
                                    return newStatus;
                                });
                                // just in case of mercure event not getting received.
                                // Should not be triggered more than once.
                                sendConversionEvents();
                            } else {
                                // Handle error
                                // console.error('An error occured, please reload the page');
                            }
                        });
                    }, 15000);
                }
            });
        }, 15000);
    }, [checkIfOrgaSlugExists, sendConversionEvents]);

    const createValidSlug = useCallback((value, callback, increment = 0) => {
        const checkedValue = slugify(
            value,
            increment > 0 ? increment + 1 : 0,
        );
        clObj.checkOrganization({ name: checkedValue }).onReady((error) => {
            if (!error) {
                createValidSlug(value, callback, (increment + 1));
            } else {
                callback(checkedValue);
            }
        });
    }, [clObj]);

    const sendNewsLetter = useCallback((user, step, cb) => {
        if (user.newsletter) {
            const dataNL = {};
            if (step === 0) {
                dataNL.email = user.email;
                dataNL.firstName = user.firstname;
                dataNL.lastName = user.lastname;

                if (hubspotActivated) {
                    const visitorToken = getCookie('hubspotutk');
                    if (visitorToken) {
                        dataNL.hutk = visitorToken;
                    }
                }
                axios.post(`${process.env.GATSBY_API_URL}/arya/newsletters`, dataNL).then(() => {
                    cb();
                }).catch(() => {
                    cb(true);
                });
            } else {
                dataNL.company = user.organizationBillingDescription;
                axios.patch(
                    `${process.env.GATSBY_API_URL}/arya/newsletters/${user.id}`,
                    dataNL,
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        },
                    },
                ).then(() => {
                    cb();
                }).catch(() => {
                    cb(true);
                });
            }
        } else {
            cb();
        }
    }, [hubspotActivated]);

    // TODO : NUAGE-1925 -> Refactor this
    const goToNextStep = useCallback(async (res) => {
        if (actualStep > steps.length - 1) { return; }
        // Handling Payment Step
        if (actualStep === 3) {
            const {
                elt: elements,
                st: stripe,
                typePayment: stripeTypePayment,
            } = stripeElement.current;

            const cardElement = elements.getElement('cardNumber');
            // Handling Stripe
            if (stripeTypePayment === 'cb') {
                setLoading(true);
                try {
                    const stripeSetupIntent = userProfile.extensions.find((element) => element.name === 'stripe_setupintent');

                    // Do an ajax call to Stripe to save the card
                    await saveCardToStripeCustomer(
                        stripeSetupIntent,
                        res['customer-cardholder-name'],
                        stripe,
                        elements,
                        cardElement,
                        {
                            mail: userProfile.email,
                            address: {
                                city: userProfile.organizationCity,
                                country: userProfile.organizationCountry,
                                line1: userProfile.organizationAddress,
                                postal_code: userProfile.organizationZipcode,
                                state: null,
                            },
                        },
                    );

                    const curatedRes = curateFormData(res, actualStep);

                    const userToSend = userProfile;

                    Object.entries(curatedRes).forEach(([key, value]) => {
                        userToSend[key] = value;
                    });

                    dispatchGtagEvent('submit', {
                        event_category: 'form',
                        event_label: 'moyen de paiement',
                    });

                    userToSend.flush().onReady((error, data) => {
                        if (error) {
                            handleGlobalErrors();
                        } else {
                            setErrorWindow(false);
                            handleData(error, data);
                            setPaymentDone(true);
                            setLoading(false);
                        }
                    });
                } catch (e) {
                    setTextErrorWindow(_string('error_window_stripe'));
                    setErrorWindow(true);
                    setLoading(false);
                }
            } else {
                dispatchGtagEvent('submit', {
                    event_category: 'form',
                    event_label: 'moyen de paiement',
                });
                setSkipPayment(true);
            }
        } else {
            setLoading(true);
            const curatedRes = curateFormData(res, actualStep);

            const userToSend = userProfile;

            Object.entries(curatedRes).forEach(([key, value]) => {
                userToSend[key] = value;
            });
            // Handling Last Step
            if (actualStep === 4) {
                // Last check, if the user try to force a name
                // when the fiel is checked
                checkIfOrgaSlugExists(res['customer-slug'], (isExist) => {
                    if (isExist) {
                        setOrganizationError('customer-slug', {
                            type: 'manual',
                            message: _string('error_slug_validate'),
                        });
                        setLoading(false);
                    } else {
                        dispatchGtagEvent('submit', {
                            event_category: 'form',
                            event_label: 'espace de travail',
                        });

                        userToSend.status = 'ready';
                        userToSend.flush().onReady((error, data) => {
                            if (error) {
                                handleGlobalErrors();
                            } else {
                                // We set the bool to true so that we send tracking events
                                // only once with the sendConversionEvents func. inside handleData
                                isWaitingForConfirmation.current = true;
                                handleData(error, data);
                            }
                        });
                        // TODO : This does not spark joy
                        ultraTemporaryDirtyAndNotAcceptableFunction(res['customer-slug']);
                    }
                });
            // Handling CGU step
            } else if (actualStep === 2) {
                userToSend.flush().onReady((error, data) => {
                    if (error) {
                        handleGlobalErrors();
                    } else {
                        dispatchGtagEvent('submit', {
                            event_category: 'form',
                            event_label: 'conditions générales',
                        });
                        handleData(error, data);
                    }
                });
            // Handling Other Steps (Newsletter Send)
            } else {
                sendNewsLetter(userToSend, actualStep, (errNL) => {
                    if (errNL) {
                        handleGlobalErrors();
                    } else {
                        userToSend.flush().onReady((error, data) => {
                            if (error) {
                                handleGlobalErrors();
                            } else {
                                let textStep;
                                switch (actualStep) {
                                // Handling Organization step
                                case 1: {
                                    textStep = 'organisation';
                                    break;
                                }
                                // Handling First step (First and Lastname)
                                default: {
                                    textStep = 'utilisateur';
                                    if (data.newsletter) {
                                        dispatchGtagEvent('submit', {
                                            event_category: 'form',
                                            event_label: 'newsletter',
                                        });
                                    }
                                    break;
                                }
                                }
                                dispatchGtagEvent('submit', {
                                    event_category: 'form',
                                    event_label: textStep,
                                });
                                handleData(error, data);
                            }
                        });
                    }
                });
            }
        }
    }, [
        actualStep,
        steps.length,
        userProfile,
        curateFormData,
        handleGlobalErrors,
        handleData,
        _string,
        checkIfOrgaSlugExists,
        setOrganizationError,
        ultraTemporaryDirtyAndNotAcceptableFunction,
        sendNewsLetter,
    ]);

    useEffect(() => {
        if (!userProfile || typeof paymentDone === 'boolean') { return; }
        const stripeSetupIntent = userProfile.extensions.find((element) => element.name === 'stripe_setupintent');
        fetch(`https://api.stripe.com/v1/setup_intents/${stripeSetupIntent.data.id}?client_secret=${stripeSetupIntent.data.client_secret}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.STRIPE_PUBLIC_KEY}`,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setPaymentDone(res.status === 'succeeded');
                setLoading(false);
            });
    }, [userProfile, paymentDone]);

    useEffect(() => {
        if (!clObj || stripePromise || params.id === '[id]') { return; }
        clObj.invitation({ id: params.id }).onReady((error, data) => {
            handleData(error, data);
        }).onUpdate((error, data) => {
            // We receive update Invitation data from here and
            // move to the next screen mostly from here with handleData.
            // Conversion events are also sent from handleData on step 5.
            handleData(error, data);
        });
        setStripePromise(loadStripe(process.env.STRIPE_PUBLIC_KEY));
    }, [clObj, params, handleData, params.id, stripePromise]);

    useEffect(() => {
        if (!userProfile || generatedOrgSlug) { return; }

        if (userProfile.organizationBillingDescription) {
            createValidSlug(userProfile.organizationBillingDescription, (valid) => {
                setGeneratedOrgSlug(valid);
                setIsCheckOrgaSlugLoading(false);
            });
        }
    }, [userProfile, skippedPayment, paymentDone, createValidSlug, generatedOrgSlug]);

    useEffect(() => {
        if (!clObj || !userProfile) { return; }

        clObj.contract({ id: userProfile.contract }).onReady((error, data) => {
            setContract(data.content);
        });
    }, [userProfile, clObj]);

    // When SkipPayment or else change
    useEffect(() => {
        if (!userProfile) { return; }

        handleData(null, userProfile);
    }, [paymentDone, skippedPayment, userProfile, handleData]);

    useEffect(() => {
        const newClObj = new NuageClient();
        setClObj(newClObj.cl);
    }, []);

    return (
        <MainLayout
            title={_string('onboarding_titleTab')}
            socials={_string('onboarding_socials')}
            footer={false}
            classTitle="onboarding"
        >
            <Elements stripe={stripePromise} options={{ fonts: elFonts }}>
                <div
                    className={`
                        onboarding__loader
                        ${loading && !userProfile ? 'onboarding__loader--loading' : ''}`
                        .replace(/\s+/g, ' ')
                        .trim()}
                >
                    <StateIcon state="loading" />
                </div>
                <LogoIconC className="onboarding__icon" />
                <div
                    className={`
                        onboarding__block
                        ${loading && !userProfile ? 'onboarding__block--loading' : ''}`
                        .replace(/\s+/g, ' ')
                        .trim()}
                >
                    <div className="onboarding__block__content">
                        { userProfile ? (
                            <div
                                className="onboarding__block__slider"
                                style={{
                                    marginLeft: `calc(100% * -${actualStep})`,
                                }}
                            >
                                <OnboardingAccount
                                    onSubmit={accountSubmit(goToNextStep)}
                                    disabled={actualStep !== 0}
                                    control={accountControl}
                                    errors={accountErrors}
                                    loading={loading}
                                    mail={userProfile?.email}
                                    setNewsletter={setNewsletter}
                                />
                                <OnboardingCustomer
                                    onSubmit={customerSubmit(goToNextStep)}
                                    disabled={actualStep !== 1}
                                    control={customerControl}
                                    errors={customerErrors}
                                    loading={loading}
                                />
                                <OnboardingContract
                                    onSubmit={contractSubmit(goToNextStep)}
                                    disabled={actualStep !== 2}
                                    contract={contract}
                                    control={contractControl}
                                    errors={contractErrors}
                                    setContractValue={setContractValue}
                                    loading={loading}
                                />
                                <OnboardingPayment
                                    onSubmit={paymentSubmit(goToNextStep)}
                                    disabled={actualStep !== 3}
                                    control={paymentControl}
                                    errors={paymentErrors}
                                    setPaymentValue={setPaymentValue}
                                    loading={loading}
                                    returnStripe={getStripeElement}
                                    sendStripeError={setStripeError}
                                    coupon={coupon}
                                    sponsor={sponsor}
                                    typePayment={typePayment}
                                    setTypePayment={setTypePayment}
                                />
                                <OnboardingOrganization
                                    onSubmit={organizationSubmit(goToNextStep)}
                                    disabled={actualStep !== 4}
                                    userProfile={userProfile}
                                    control={organizationControl}
                                    setOrganizationValue={setOrganizationValue}
                                    checkSlug={checkIfOrgaSlugExists}
                                    generatedSlug={generatedOrgSlug}
                                    setError={setOrganizationError}
                                    clearErrors={clearOrganizationErrors}
                                    errors={organizationErrors}
                                    trigger={organizationTrigger}
                                    loading={loading}
                                />
                                <OnboardingValidate
                                    slug={userProfile.organizationName}
                                />
                            </div>
                        ) : !loading && (
                            <OnboardingError
                                errorType={globalErrorType}
                            />
                        )}
                    </div>
                    <div className="onboarding__block__controls">
                        <div className="onboarding__block__progress">
                            <StepsOnboarding
                                steps={steps}
                                actualStep={actualStep}
                            />
                        </div>
                        <div className="onboarding__block__buttons">
                            {actualStep < steps.length && actualStep !== -1 && (
                                <ComplexButton
                                    className={!userProfile && !loading ? 'onboarding__block__buttons__btn--hide' : ''}
                                    onClick={actualSubmit(goToNextStep)}
                                    disabled={loading
                                        || errorsLogged
                                        || (actualStep === 3 && stripeError)
                                        || (actualStep === 4 && isCheckOrgaSlugLoading)}
                                    rightIcon="ico-arrow"
                                    loading={loading}
                                >
                                    {_string('onboarding_next_btn')}
                                </ComplexButton>
                            )}
                        </div>
                    </div>
                    <ErrorWindow
                        text={textErrorWindow}
                        isOpen={isOpenErrorWindow}
                        onClose={() => {
                            setLoading(false);
                            setErrorWindow(false);
                        }}
                    />
                </div>
            </Elements>
        </MainLayout>
    );
};

OnBoardingPage.propTypes = {
    params: PropTypes.objectOf(PropTypes.string),
};

OnBoardingPage.defaultProps = {
    params: null,
};

export default OnBoardingPage;
