import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

const StripeField = (props) => {
    const { sType, options, label, onUpdate, onChange } = props;

    const renderComponent = (elt, optionsRender) => {
        switch (elt) {
        case 'number': return <CardNumberElement id={`card-${elt}`} tabIndex={-1} className={`card-${elt}`} options={optionsRender} onReady={() => onUpdate()} onChange={onChange} />;
        case 'exp': return <CardExpiryElement id={`card-${elt}`} tabIndex={-1} className={`card-${elt}`} options={optionsRender} onChange={onChange} />;
        case 'cvc': return <CardCvcElement id={`card-${elt}`} tabIndex={-1} className={`card-${elt}`} options={optionsRender} onChange={onChange} />;
        default: return null;
        }
    };

    return (
        <div className={`stripe-input stripe-input--${sType}`}>
            <div className="label stripe-label">
                <label htmlFor={`card-${sType}`}>{label}</label>
            </div>
            {renderComponent(sType, options)}
        </div>
    );
};

StripeField.propTypes = {
    sType: PropTypes.string,
    options: PropTypes.objectOf(PropTypes.object),
    label: PropTypes.string,
    onUpdate: PropTypes.func,
    onChange: PropTypes.func,
};

StripeField.defaultProps = {
    sType: null,
    options: null,
    label: null,
    onUpdate: null,
    onChange: null,
};

export default StripeField;
