import React from 'react';
import PropTypes from 'prop-types';

import { BasicButton } from '@nuage/ui-components';

import ValidateHand from '@images/svg/hand.svg';

import lang from '@utils/lang';
import { dispatchGtagEvent } from '@utils/eventsManager';

import OnboardingForm from './onboardingForm';

import './onboardingValidate.scss';

const OnboardingValidate = ({ slug }) => {
    const { _string, _node } = lang;

    return (
        <OnboardingForm
            title={_node('onboarding_validate_title')}
            sub={_string('onboarding_validate_sub')}
            className="onboarding-validate"
        >
            <ValidateHand className="onboarding-validate__illu" />
            <BasicButton
                className="onboarding-validate__button"
                onClick={() => {
                    dispatchGtagEvent('click', {
                        event_category: 'cta',
                        event_label: 'connexion',
                    });
                }}
                link={`http://${slug}.${process.env.APP_BASE_DOMAIN}`}
                size="large"
                tabIndex={-1}
            >
                {_string('onboarding_last_button')}
            </BasicButton>
        </OnboardingForm>
    );
};

OnboardingValidate.propTypes = {
    slug: PropTypes.string,
};

OnboardingValidate.defaultProps = {
    slug: '#',
};

export default OnboardingValidate;
