import React from 'react';
import PropTypes from 'prop-types';

import { Title } from '@nuage/ui-components';

// Illustrations
import CatError from '@images/svg/cat.svg';
import PeriscopeError from '@images/svg/periscope.svg';

import lang from '@utils/lang';

import './onboardingError.scss';

const OnboardingError = ({ errorType }) => {
    const { _string } = lang;
    return (
        <div className="onboarding-error">
            <div className="onboarding-error__text">
                <Title className="onboarding-error__text__main">
                    {_string(`onboarding_error_${errorType}_title`)}
                </Title>
                <p className="onboarding-error__text__sub">
                    {_string(`onboarding_error_${errorType}_sub`)}
                </p>
            </div>
            <div className="onboarding-error__illustration">
                {errorType === '404' ? <PeriscopeError /> : <CatError />}
            </div>
        </div>
    );
};

OnboardingError.propTypes = {
    errorType: PropTypes.string,
};

OnboardingError.defaultProps = {
    errorType: 'global',
};

export default OnboardingError;
