import React from 'react';
import PropTypes from 'prop-types';

import zxcvbn from 'zxcvbn';

import { BasicCheckbox, A } from '@nuage/ui-components';

import { Controller } from 'react-hook-form';

import ComplexField from '@molecules/interactive/complexField';
import StrengthMeter from '@molecules/visual/strengthMeter';
import RandomUser from '@molecules/visual/randomUser';

import lang from '@utils/lang';

import OnboardingForm from './onboardingForm';

import './onboardingAccount.scss';

const OnboardingAccount = ({
    onSubmit,
    loading,
    control,
    errors,
    disabled,
    mail,
    setNewsletter,
}) => {
    const { _string, _node } = lang;

    return (
        <OnboardingForm
            title={_node('onboarding_account_title')}
            sub={_string('onboarding_account_sub')}
            className="onboarding-account"
            loading={loading}
            onSubmit={onSubmit}
            errors={errors}
        >
            <form className="onboarding-account__form">
                <ComplexField
                    name="lastname"
                    id="lastname"
                    rules={{
                        required: _string('error_lastname_required'),
                        maxLength: {
                            value: 80,
                            message: _string('error_lastname_max', { length: 80 }),
                        },
                    }}
                    label={_string('onboarding_field_lastname')}
                    controlForm={control}
                    disabled={disabled}
                />
                <ComplexField
                    name="firstname"
                    id="firstname"
                    rules={{
                        required: _string('error_firstname_required'),
                        maxLength: {
                            value: 80,
                            message: _string('error_firstname_max', { length: 80 }),
                        },
                    }}
                    label={_string('onboarding_field_firstname')}
                    controlForm={control}
                    disabled={disabled}
                />
                <ComplexField
                    type="email"
                    name="email"
                    id="email"
                    defaultValue={mail}
                    locked
                    rules={{
                        required: _string('error_mail_required'),
                    }}
                    label={_string('onboarding_field_mail')}
                    controlForm={control}
                    disabled={disabled}
                    rightIcon="ico-lock"
                    rightIconTitle={_string('onboarding_field_locked')}
                />
                <ComplexField
                    type="password"
                    name="password"
                    id="password"
                    rules={{
                        required: _string('error_password_required'),
                        maxLength: {
                            value: 255,
                            message: _string('error_password_max', { length: 255 }),
                        },
                        minLength: {
                            value: 6,
                            message: _string('error_password_min', { length: 6 }),
                        },
                    }}
                    label={_string('onboarding_field_password')}
                    controlForm={control}
                    disabled={disabled}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    footerField={(value) => (
                        <>
                            <StrengthMeter
                                value={value ? zxcvbn(value).score : null}
                            />
                            <p className="onboarding-account__strength-text">{_string('onboarding_password_hint')}</p>
                        </>
                    )}
                />
                <Controller
                    name="newsletter"
                    control={control}
                    defaultValue={false}
                    render={({
                        field: { onBlur, value, name: nameField },
                    }) => (
                        <BasicCheckbox
                            id="newsletter"
                            className="onboarding_field_newsletter"
                            name={nameField}
                            onClick={() => { setNewsletter('newsletter', !value, { shouldValidate: true }); }}
                            onBlur={onBlur}
                            disabled={disabled}
                            checked={value}
                        >
                            {_string('onboarding_customer_newsletter1')}
                            <A linkProps={{
                                href: '/mentions-legales#Politique_de_donnees_personnelles',
                                onClick: () => {},
                            }}
                            >
                                {_string('onboarding_customer_newsletter_link')}
                            </A>
                            {_string('onboarding_customer_newsletter2')}
                        </BasicCheckbox>
                    )}
                />
            </form>
            <div className="onboarding-account__illu">
                <RandomUser />
            </div>
        </OnboardingForm>
    );
};

OnboardingAccount.propTypes = {
    onSubmit: PropTypes.func,
    control: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ])),
    mail: PropTypes.string.isRequired,
    errors: PropTypes.objectOf(PropTypes.object),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    setNewsletter: PropTypes.func,
};

OnboardingAccount.defaultProps = {
    onSubmit: () => {},
    loading: true,
    disabled: false,
    control: null,
    errors: null,
    setNewsletter: null,
};

export default OnboardingAccount;
