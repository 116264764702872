import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const RandomUser = ({ fixed }) => {
    const ChosenImg = useMemo(() => {
        const numb = fixed || Math.floor(Math.random() * 15) + 1;
        // TODO : Find a better solution ?
        // eslint-disable-next-line import/no-dynamic-require, global-require
        return require(`@images/svg/usersPortrait/u${numb}.svg`);
    }, [fixed]);

    // const ChosenImg = require(`@images/svg/usersPortrait/${numb}.svg`);
    return (
        <ChosenImg className="random-user" />
    );
};

RandomUser.propTypes = {
    fixed: PropTypes.number,
};

RandomUser.defaultProps = {
    fixed: null,
};

export default RandomUser;
