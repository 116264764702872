import React from 'react';
import PropTypes from 'prop-types';

import { BasicButton } from '@nuage/ui-components';

import './errorWindow.scss';

const ErrorWindow = ({ text, isOpen, onClose }) => (
    <div className={`error-window${isOpen ? ' error-window--open' : ''}`}>
        <BasicButton
            leftIcon="ico-close"
            color="white"
            format="text"
            onClick={onClose}
            className="error-window__arrow"
        />
        { text }
    </div>
);

ErrorWindow.propTypes = {
    text: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

ErrorWindow.defaultProps = {
    text: null,
    isOpen: false,
    onClose: null,
};

export default ErrorWindow;
