import React from 'react';
import PropTypes from 'prop-types';

import { Title, ComplexButton } from '@nuage/ui-components';

import lang from '@utils/lang';

import './onboardingForm.scss';

const OnboardingForm = ({ title, sub, className, children, onSubmit, loading, errors }) => {
    const { _string } = lang;

    return (
        <div className={`onboarding-form ${className}`.trim()}>
            <div className="onboarding-form__title">
                <Title className="onboarding-form__title__main">{title}</Title>
                <p className="onboarding-form__title__sub">{sub}</p>
            </div>
            <div className="onboarding-form__content">
                { children }
            </div>
            {onSubmit && (
                <div className="onboarding-form__mobile-buttons">
                    <ComplexButton
                        onClick={onSubmit}
                        disabled={loading || (errors && Object.keys(errors).length !== 0)}
                        rightIcon="ico-arrow"
                        loading={loading}
                    >
                        {_string('onboarding_next_btn')}
                    </ComplexButton>
                </div>
            )}
        </div>
    );
};

OnboardingForm.propTypes = {
    title: PropTypes.node,
    sub: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
    errors: PropTypes.objectOf(PropTypes.object),
};

OnboardingForm.defaultProps = {
    title: null,
    sub: '',
    className: '',
    children: null,
    onSubmit: null,
    loading: true,
    errors: null,
};

export default OnboardingForm;
