import React from 'react';
import PropTypes from 'prop-types';

import { ComplexSelect, ErrorMessage } from '@nuage/ui-components';
import { Controller } from 'react-hook-form';

import ComplexField from '@molecules/interactive/complexField';
import Desk from '@images/svg/desk.svg';

import lang from '@utils/lang';

import OnboardingForm from './onboardingForm';

import './onboardingCustomer.scss';

const OnboardingCustomer = ({ onSubmit, loading, control, errors, disabled }) => {
    const { _string, _node } = lang;
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const countries = require(`@utils/countries/${_string('code')}.js`).default;

    return (
        <OnboardingForm
            title={_node('onboarding_customer_title')}
            sub={_string('onboarding_customer_sub')}
            className="onboarding-customer"
            loading={loading}
            onSubmit={onSubmit}
            errors={errors}
        >
            <form className="onboarding-customer__form">
                <ComplexField
                    name="customer-description"
                    id="customer-description"
                    rules={{
                        required: _string('error_orgName_required'),
                        maxLength: {
                            value: 128,
                            message: _string('error_orgName_max', { length: 128 }),
                        },
                    }}
                    label={_string('onboarding_field_orgName')}
                    placeholder={_string('onboarding_field_orgName_placeholder')}
                    controlForm={control}
                    disabled={disabled}
                />
                <div className="onboarding-customer__address">
                    <ComplexField
                        name="customer-addr-line-1"
                        id="customer-addr-line-1"
                        rules={{
                            required: _string('error_adress_required'),
                            maxLength: {
                                value: 125,
                                message: _string('error_adress_max', { length: 125 }),
                            },
                        }}
                        label={_string('onboarding_field_adress')}
                        placeholder={_string('onboarding_field_adress_street_placeholder')}
                        controlForm={control}
                        disabled={disabled}
                    />
                    <ComplexField
                        name="customer-addr-line-2"
                        id="customer-addr-line-2"
                        rules={{
                            maxLength: {
                                value: 125,
                                message: _string('error_adress_max', { length: 125 }),
                            },
                        }}
                        placeholder={_string('onboarding_field_adress_appartment_placeholder')}
                        controlForm={control}
                        disabled={disabled}
                    />
                </div>
                <div className="onboarding-customer__postal">
                    <ComplexField
                        name="customer-zip-code"
                        id="customer-zip-code"
                        className="onboarding-customer__postal__postal-field"
                        rules={{
                            required: _string('error_postalCode_required'),
                            maxLength: {
                                value: 32,
                                message: _string('error_postalCode_max', { length: 32 }),
                            },
                        }}
                        label={_string('onboarding_field_postalCode')}
                        controlForm={control}
                        disabled={disabled}
                        displayErrors={false}
                    />
                    <ComplexField
                        name="customer-city"
                        id="customer-city"
                        className="onboarding-customer__postal__city-field"
                        rules={{
                            required: _string('error_city_required'),
                            maxLength: {
                                value: 255,
                                message: _string('error_city_max', { length: 255 }),
                            },
                        }}
                        label={_string('onboarding_field_city')}
                        controlForm={control}
                        disabled={disabled}
                        displayErrors={false}
                    />
                </div>
                <div className="onboarding-customer__postal-errors">
                    { errors['customer-zip-code'] && (
                        <ErrorMessage opened>
                            {errors['customer-zip-code'].message}
                        </ErrorMessage>
                    )}
                    { errors['customer-city'] && (
                        <ErrorMessage opened>
                            {errors['customer-city'].message}
                        </ErrorMessage>
                    )}
                </div>
                <Controller
                    name="customer-country"
                    control={control}
                    defaultValue={{ label: 'France', value: 'FR' }}
                    // rules={rules}
                    render={({
                        field: { onChange, onBlur, value, name: nameField },
                    }) => (
                        <ComplexSelect
                            values={countries}
                            name={nameField}
                            className="customer-country"
                            id="customer-country"
                            label={_string('onboarding_field_country')}
                            placeholder={_string('search_text')}
                            selectedValue={value}
                            isSearchable
                            onClick={onChange}
                            onBlur={onBlur}
                            state={value ? 'primary' : null}
                            disabled
                            loading={loading}
                            arrowTitle={_string('onboarding_field_locked')}
                        />
                    )}
                />
                <ComplexField
                    name="customer-vat"
                    id="customer-vat"
                    className="onboarding-customer__vat"
                    // TODO : Improve this when we open to other countries
                    rules={{
                        pattern: {
                            value: /^(FR)([0-9A-Z]{2}[0-9]{9}$)/i,
                            message: _string('error_vat_pattern'),
                        },
                    }}
                    label={_string('onboarding_field_vat')}
                    controlForm={control}
                    disabled={disabled}
                />
            </form>
            <div className="onboarding-customer__illu">
                <Desk />
            </div>
        </OnboardingForm>
    );
};

OnboardingCustomer.propTypes = {
    onSubmit: PropTypes.func,
    control: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ])),
    errors: PropTypes.objectOf(PropTypes.object),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
};

OnboardingCustomer.defaultProps = {
    onSubmit: () => {},
    loading: true,
    disabled: false,
    control: null,
    errors: null,
};

export default OnboardingCustomer;
