import React from 'react';
import PropTypes from 'prop-types';

import { ScrollBox, BasicCheckbox, ErrorMessage } from '@nuage/ui-components';
import { Controller } from 'react-hook-form';

import lang from '@utils/lang';

import OnboardingForm from './onboardingForm';

import './onboardingContract.scss';

const OnboardingContract = ({
    onSubmit,
    loading,
    control,
    errors,
    disabled,
    setContractValue,
    contract,
}) => {
    const { _string, _node, _markDown } = lang;
    return (
        <OnboardingForm
            title={_node('onboarding_contract_title')}
            sub={_string('onboarding_contract_sub')}
            loading={loading}
            className="onboarding-contract"
            onSubmit={onSubmit}
        >
            <ScrollBox numOfLoadingLines={19} loading={!contract}>
                {contract ? _markDown(contract, true) : ''}
            </ScrollBox>
            <Controller
                name="customer-contract"
                control={control}
                defaultValue={false}
                rules={{
                    required: _string('error_cgu_required'),
                }}
                render={({
                    field: { onBlur, value, name: nameField },
                }) => (
                    <>
                        <BasicCheckbox
                            id="customer-contract"
                            name={nameField}
                            onClick={() => { setContractValue('customer-contract', !value, { shouldValidate: true }); }}
                            onBlur={onBlur}
                            disabled={disabled}
                            checked={value}
                        >
                            {_string('onboarding_cgu_check_label')}
                        </BasicCheckbox>

                        { errors['customer-contract'] && (
                            <ErrorMessage opened>
                                {errors['customer-contract'].message}
                            </ErrorMessage>
                        )}
                    </>
                )}
            />
        </OnboardingForm>
    );
};

OnboardingContract.propTypes = {
    onSubmit: PropTypes.func,
    control: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ])),
    errors: PropTypes.objectOf(PropTypes.object),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    setContractValue: PropTypes.func,
    contract: PropTypes.string,
};

OnboardingContract.defaultProps = {
    onSubmit: () => {},
    loading: true,
    disabled: false,
    control: null,
    errors: null,
    setContractValue: null,
    contract: null,
};

export default OnboardingContract;
