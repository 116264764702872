// Borrowed from https://github.com/umpirsky/country-list
const countries = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'ZA', label: 'Afrique du Sud' },
    { value: 'AL', label: 'Albanie' },
    { value: 'DZ', label: 'Algérie' },
    { value: 'DE', label: 'Allemagne' },
    { value: 'AD', label: 'Andorre' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctique' },
    { value: 'AG', label: 'Antigua-et-Barbuda' },
    { value: 'SA', label: 'Arabie saoudite' },
    { value: 'AR', label: 'Argentine' },
    { value: 'AM', label: 'Arménie' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australie' },
    { value: 'AT', label: 'Autriche' },
    { value: 'AZ', label: 'Azerbaïdjan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahreïn' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbade' },
    { value: 'BE', label: 'Belgique' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Bénin' },
    { value: 'BM', label: 'Bermudes' },
    { value: 'BT', label: 'Bhoutan' },
    { value: 'BY', label: 'Biélorussie' },
    { value: 'BO', label: 'Bolivie' },
    { value: 'BA', label: 'Bosnie-Herzégovine' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BR', label: 'Brésil' },
    { value: 'BN', label: 'Brunéi Darussalam' },
    { value: 'BG', label: 'Bulgarie' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'KH', label: 'Cambodge' },
    { value: 'CM', label: 'Cameroun' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cap-Vert' },
    { value: 'CL', label: 'Chili' },
    { value: 'CN', label: 'Chine' },
    { value: 'CY', label: 'Chypre' },
    { value: 'CO', label: 'Colombie' },
    { value: 'KM', label: 'Comores' },
    { value: 'CG', label: 'Congo-Brazzaville' },
    { value: 'CD', label: 'Congo-Kinshasa' },
    { value: 'KP', label: 'Corée du Nord' },
    { value: 'KR', label: 'Corée du Sud' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CI', label: 'Côte d’Ivoire' },
    { value: 'HR', label: 'Croatie' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'DK', label: 'Danemark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominique' },
    { value: 'EG', label: 'Égypte' },
    { value: 'AE', label: 'Émirats arabes unis' },
    { value: 'EC', label: 'Équateur' },
    { value: 'ER', label: 'Érythrée' },
    { value: 'ES', label: 'Espagne' },
    { value: 'EE', label: 'Estonie' },
    { value: 'SZ', label: 'Eswatini' },
    { value: 'VA', label: 'État de la Cité du Vatican' },
    { value: 'FM', label: 'États fédérés de Micronésie' },
    { value: 'US', label: 'États-Unis' },
    { value: 'ET', label: 'Éthiopie' },
    { value: 'FJ', label: 'Fidji' },
    { value: 'FI', label: 'Finlande' },
    { value: 'FR', label: 'France' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambie' },
    { value: 'GE', label: 'Géorgie' },
    { value: 'GS', label: 'Géorgie du Sud et îles Sandwich du Sud' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Grèce' },
    { value: 'GD', label: 'Grenade' },
    { value: 'GL', label: 'Groenland' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernesey' },
    { value: 'GN', label: 'Guinée' },
    { value: 'GQ', label: 'Guinée équatoriale' },
    { value: 'GW', label: 'Guinée-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'GF', label: 'Guyane française' },
    { value: 'HT', label: 'Haïti' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HU', label: 'Hongrie' },
    { value: 'BV', label: 'Île Bouvet' },
    { value: 'CX', label: 'Île Christmas' },
    { value: 'IM', label: 'Île de Man' },
    { value: 'NF', label: 'Île Norfolk' },
    { value: 'AX', label: 'Îles Åland' },
    { value: 'KY', label: 'Îles Caïmans' },
    { value: 'CC', label: 'Îles Cocos' },
    { value: 'CK', label: 'Îles Cook' },
    { value: 'FO', label: 'Îles Féroé' },
    { value: 'HM', label: 'Îles Heard et McDonald' },
    { value: 'FK', label: 'Îles Malouines' },
    { value: 'MP', label: 'Îles Mariannes du Nord' },
    { value: 'MH', label: 'Îles Marshall' },
    { value: 'UM', label: 'Îles mineures éloignées des États-Unis' },
    { value: 'PN', label: 'Îles Pitcairn' },
    { value: 'SB', label: 'Îles Salomon' },
    { value: 'TC', label: 'Îles Turques-et-Caïques' },
    { value: 'VG', label: 'Îles Vierges britanniques' },
    { value: 'VI', label: 'Îles Vierges des États-Unis' },
    { value: 'IN', label: 'Inde' },
    { value: 'ID', label: 'Indonésie' },
    { value: 'IQ', label: 'Irak' },
    { value: 'IR', label: 'Iran' },
    { value: 'IE', label: 'Irlande' },
    { value: 'IS', label: 'Islande' },
    { value: 'IL', label: 'Israël' },
    { value: 'IT', label: 'Italie' },
    { value: 'JM', label: 'Jamaïque' },
    { value: 'JP', label: 'Japon' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordanie' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KG', label: 'Kirghizistan' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KW', label: 'Koweït' },
    { value: 'RE', label: 'La Réunion' },
    { value: 'LA', label: 'Laos' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LV', label: 'Lettonie' },
    { value: 'LB', label: 'Liban' },
    { value: 'LR', label: 'Libéria' },
    { value: 'LY', label: 'Libye' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lituanie' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MK', label: 'Macédoine du Nord' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MY', label: 'Malaisie' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malte' },
    { value: 'MA', label: 'Maroc' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MU', label: 'Maurice' },
    { value: 'MR', label: 'Mauritanie' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexique' },
    { value: 'MD', label: 'Moldavie' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolie' },
    { value: 'ME', label: 'Monténégro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar (Birmanie)' },
    { value: 'NA', label: 'Namibie' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Népal' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigéria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NO', label: 'Norvège' },
    { value: 'NC', label: 'Nouvelle-Calédonie' },
    { value: 'NZ', label: 'Nouvelle-Zélande' },
    { value: 'OM', label: 'Oman' },
    { value: 'UG', label: 'Ouganda' },
    { value: 'UZ', label: 'Ouzbékistan' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palaos' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papouasie-Nouvelle-Guinée' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'NL', label: 'Pays-Bas' },
    { value: 'BQ', label: 'Pays-Bas caribéens' },
    { value: 'PE', label: 'Pérou' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PL', label: 'Pologne' },
    { value: 'PF', label: 'Polynésie française' },
    { value: 'PR', label: 'Porto Rico' },
    { value: 'PT', label: 'Portugal' },
    { value: 'QA', label: 'Qatar' },
    { value: 'HK', label: 'R.A.S. chinoise de Hong Kong' },
    { value: 'MO', label: 'R.A.S. chinoise de Macao' },
    { value: 'CF', label: 'République centrafricaine' },
    { value: 'DO', label: 'République dominicaine' },
    { value: 'RO', label: 'Roumanie' },
    { value: 'GB', label: 'Royaume-Uni' },
    { value: 'RU', label: 'Russie' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'EH', label: 'Sahara occidental' },
    { value: 'BL', label: 'Saint-Barthélemy' },
    { value: 'KN', label: 'Saint-Christophe-et-Niévès' },
    { value: 'SM', label: 'Saint-Marin' },
    { value: 'MF', label: 'Saint-Martin' },
    { value: 'SX', label: 'Saint-Martin (partie néerlandaise)' },
    { value: 'PM', label: 'Saint-Pierre-et-Miquelon' },
    { value: 'VC', label: 'Saint-Vincent-et-les-Grenadines' },
    { value: 'SH', label: 'Sainte-Hélène' },
    { value: 'LC', label: 'Sainte-Lucie' },
    { value: 'SV', label: 'Salvador' },
    { value: 'WS', label: 'Samoa' },
    { value: 'AS', label: 'Samoa américaines' },
    { value: 'ST', label: 'Sao Tomé-et-Principe' },
    { value: 'SN', label: 'Sénégal' },
    { value: 'RS', label: 'Serbie' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapour' },
    { value: 'SK', label: 'Slovaquie' },
    { value: 'SI', label: 'Slovénie' },
    { value: 'SO', label: 'Somalie' },
    { value: 'SD', label: 'Soudan' },
    { value: 'SS', label: 'Soudan du Sud' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SE', label: 'Suède' },
    { value: 'CH', label: 'Suisse' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard et Jan Mayen' },
    { value: 'SY', label: 'Syrie' },
    { value: 'TJ', label: 'Tadjikistan' },
    { value: 'TW', label: 'Taïwan' },
    { value: 'TZ', label: 'Tanzanie' },
    { value: 'TD', label: 'Tchad' },
    { value: 'CZ', label: 'Tchéquie' },
    { value: 'TF', label: 'Terres australes françaises' },
    { value: 'IO', label: 'Territoire britannique de l’océan Indien' },
    { value: 'PS', label: 'Territoires palestiniens' },
    { value: 'TH', label: 'Thaïlande' },
    { value: 'TL', label: 'Timor oriental' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinité-et-Tobago' },
    { value: 'TN', label: 'Tunisie' },
    { value: 'TM', label: 'Turkménistan' },
    { value: 'TR', label: 'Turquie' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'WF', label: 'Wallis-et-Futuna' },
    { value: 'YE', label: 'Yémen' },
    { value: 'ZM', label: 'Zambie' },
    { value: 'ZW', label: 'Zimbabwe' },
];

export default countries;
