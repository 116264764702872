import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import lang from '@utils/lang';

import './strengthMeter.scss';

const StrengthMeter = ({ className, value }) => {
    const { _string } = lang;

    const validity = useMemo(() => {
        switch (value) {
        case null:
            return '';
        case 0:
            return 'error';
        case 1:
            return 'warning';
        default:
            return 'success';
        }
    }, [value]);

    return (
        <div className={`strength-meter strength-meter--${validity} ${className}`.trim()}>
            <div className={`strength-meter__bar ${value >= 0 ? `strength-meter__bar--${validity}` : ''}`.trim()} />
            <div className={`strength-meter__bar ${value >= 1 ? `strength-meter__bar--${validity}` : ''}`.trim()} />
            <div className={`strength-meter__bar ${value > 1 ? `strength-meter__bar--${validity}` : ''}`.trim()} />
            {validity.length > 0 && (
                <div className="strength-meter__label">
                    {_string(`error_strength_pass_${validity}`)}
                </div>
            )}
        </div>
    );
};

StrengthMeter.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
};

StrengthMeter.defaultProps = {
    className: '',
    value: null,
};

export default StrengthMeter;
