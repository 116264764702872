import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ErrorMessage } from '@nuage/ui-components';

import ComplexField from '@molecules/interactive/complexField';
import Build from '@images/svg/build.svg';

import lang from '@utils/lang';

import OnboardingForm from './onboardingForm';

import { debounce } from '../../helpers';

import './onboardingOrganization.scss';

const OnboardingOrganization = ({
    onSubmit,
    loading,
    control,
    setError,
    disabled,
    userProfile,
    setOrganizationValue,
    checkSlug,
    errors,
    clearErrors,
    generatedSlug,
    trigger,
}) => {
    const { _string, _node } = lang;

    const [checkSlugLoading, setSlugLoading] = useState(false);

    const [generatedSlugSet, setSlugGenerated] = useState(false);
    const [suggestedNameSet, setSuggestedName] = useState(false);
    const [slugTaken, setSlugTaken] = useState(false);

    const onChangeCheck = async (evt) => {
        setSlugLoading(true);
        const { value } = evt.target;
        checkSlug(value, (isExist) => {
            if (isExist) {
                setError('customer-slug', {
                    type: 'manual',
                    message: _string('error_slug_validate'),
                });
                setSlugTaken(true);
            } else {
                setSlugTaken(false);
                clearErrors('customer-slug');
                trigger();
            }
            setSlugLoading(false);
        });
    };

    useEffect(() => {
        if (userProfile.organizationBillingDescription && !suggestedNameSet) {
            setOrganizationValue(
                'customer-description',
                userProfile.organizationBillingDescription,
                { shouldValidate: true },
            );
            setSuggestedName(true);
        }
    }, [userProfile, setOrganizationValue, suggestedNameSet]);

    useEffect(() => {
        if (generatedSlug && !generatedSlugSet) {
            setOrganizationValue(
                'customer-slug',
                generatedSlug,
                { shouldValidate: true },
            );
            setSlugGenerated(true);
        }
    }, [
        generatedSlug,
        setOrganizationValue,
        userProfile.organizationDescription,
        generatedSlugSet,
    ]);

    return (
        <OnboardingForm
            title={_node('onboarding_organization_title')}
            sub={_string('onboarding_organization_sub')}
            className="onboarding-organization"
            loading={loading}
            onSubmit={onSubmit}
        >
            <form className="onboarding-organization__form">
                <ComplexField
                    name="customer-description"
                    id="customer-description-2"
                    rules={{
                        required: _string('error_orgName_required'),
                        maxLength: {
                            value: 128,
                            message: _string('error_orgName_max', { length: 128 }),
                        },
                    }}
                    label={_string('onboarding_field_orgName_alt')}
                    placeholder={_string('onboarding_field_orgName_placeholder')}
                    controlForm={control}
                    disabled={disabled}
                />
                <div className="onboarding-organization__slug">
                    <ComplexField
                        name="customer-slug"
                        id="customer-slug"
                        rules={{
                            required: _string('error_slug_required'),
                            maxLength: {
                                value: 63,
                                message: _string('error_slug_max', { length: 63 }),
                            },
                            minLength: {
                                value: 4,
                                message: _string('error_slug_min', { length: 3 }),
                            },
                            pattern: {
                                value: /([a-z-])+/,
                                message: _string('error_slug_pattern_main'),
                            },
                            validate: {
                                secondPattern: (v) => /([a-z0-9-]*[a-z0-9]$)+/.test(v) || _string('error_slug_pattern_end'),
                                thirdCorrec: () => !slugTaken || _string('error_slug_validate'),
                            },
                        }}
                        label={_string('onboarding_field_slug')}
                        controlForm={control}
                        disabled={disabled}
                        onFieldChange={debounce(onChangeCheck)}
                        rightIcon={!checkSlugLoading && !errors['customer-slug'] ? 'ico-check' : null}
                        displayErrors={false}
                    />
                    <p className="onboarding-organization__slug__text">{`.${process.env.APP_BASE_DOMAIN}`}</p>
                </div>
                <div className="onboarding-customer__slug-errors">
                    { errors['customer-slug'] && (
                        <ErrorMessage opened>
                            {errors['customer-slug'].message}
                        </ErrorMessage>
                    )}
                </div>
            </form>
            <div className="onboarding-organization__illu">
                <Build />
            </div>
        </OnboardingForm>
    );
};

OnboardingOrganization.propTypes = {
    onSubmit: PropTypes.func,
    control: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ])),
    loading: PropTypes.bool,
    errors: PropTypes.objectOf(PropTypes.object),
    disabled: PropTypes.bool,
    userProfile: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool,
        PropTypes.array,
    ])),
    setOrganizationValue: PropTypes.func,
    checkSlug: PropTypes.func,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    generatedSlug: PropTypes.string,
    trigger: PropTypes.func,
};

OnboardingOrganization.defaultProps = {
    onSubmit: () => {},
    loading: true,
    disabled: false,
    control: null,
    userProfile: null,
    setOrganizationValue: null,
    checkSlug: null,
    generatedSlug: null,
    setError: null,
    clearErrors: null,
    trigger: null,
    errors: null,
};

export default OnboardingOrganization;
