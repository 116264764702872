import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStripe, useElements } from '@stripe/react-stripe-js';
import { VisualRadio, ComplexCell, ErrorMessage } from '@nuage/ui-components';
import ComplexField from '@molecules/interactive/complexField';
import StripeField from '@molecules/interactive/stripeField'; // Stripe

import lang from '@utils/lang';

import OnboardingForm from './onboardingForm';

import './onboardingPayment.scss';

const OnboardingPayment = ({
    onSubmit,
    loading,
    returnStripe,
    control,
    errors,
    disabled,
    sendStripeError,
    setPaymentValue,
    coupon,
    sponsor,
    typePayment,
    setTypePayment,
}) => {
    const { _string, _node } = lang;

    // const [typePayment, setTypePayment] = useState('cb');

    const [errNumber, setErrNumber] = useState(true);
    const [errExp, setErrExp] = useState(true);
    const [errCvc, setErrCvc] = useState(true);

    const [errNumberMess, setErrNumberMess] = useState('');
    const [errExpMess, setErrExpMess] = useState('');
    const [errCvcMess, setErrCvcMess] = useState('');

    const useOptions = () => {
        const options = {
            style: {
                base: {
                    color: '#333333',
                    // WARN : Whatch out, this isn't a variable
                    fontFamily: 'Roboto',
                    fontSize: '15px',
                    '::placeholder': {
                        color: '#7f7f7f',
                    },
                },
                invalid: {
                    color: '#424770',
                },
            },
        };
        return options;
    };

    const options = useOptions();
    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        returnStripe({ elt: elements, st: stripe, typePayment });
    });

    useEffect(() => {
        if ((errNumber || errExp || errCvc) && typePayment !== 'tbd') {
            sendStripeError(true);
        } else {
            sendStripeError(false);
        }
    }, [errCvc, errExp, errNumber, sendStripeError, typePayment]);

    useEffect(() => {
        if (typePayment === 'tbd') {
            setPaymentValue(
                'customer-cardholder-name',
                ' ',
                { shouldValidate: true },
            );
        } else {
            setPaymentValue(
                'customer-cardholder-name',
                '',
            );
        }
    }, [setPaymentValue, typePayment]);

    const onUpdate = () => {
        returnStripe({ elt: elements, st: stripe, typePayment });
    };

    const onStripeChange = (e) => {
        switch (e.elementType) {
        case 'cardNumber':
            if (e.error) {
                setErrNumberMess(e.error.message);
                setErrNumber(true);
            } else if (!e.complete) {
                setErrNumber(true);
            } else {
                setErrNumberMess('');
                setErrNumber(false);
            }
            break;
        case 'cardExpiry':
            if (e.error) {
                setErrExpMess(e.error.message);
                setErrExp(true);
            } else if (!e.complete) {
                setErrExp(true);
            } else {
                setErrExpMess('');
                setErrExp(false);
            }
            break;
        case 'cardCvc':
            if (e.error) {
                setErrCvcMess(e.error.message);
                setErrCvc(true);
            } else if (!e.complete) {
                setErrCvc(true);
            } else {
                setErrCvcMess('');
                setErrCvc(false);
            }
            break;
        default:
            break;
        }
    };

    return (
        <OnboardingForm
            title={_node('onboarding_payment_title')}
            sub={_string('onboarding_payment_sub')}
            className={`onboarding-payment${disabled ? ' onboarding-payment--disabled' : ''}`}
            loading={loading}
            onSubmit={onSubmit}
            errors={errors}
        >
            <ComplexCell
                title={(
                    <>
                        <div className="onboarding-payment__cell__title-main">
                            <VisualRadio
                                id="onboarding-radio-cb"
                                selected={typePayment === 'cb'}
                                tabIndex={-1}
                            />
                            <p className="onboarding-payment__cell__title-text">{_string('onboarding_cb_choice_cb')}</p>
                        </div>
                        {((coupon && coupon.value) || (sponsor && sponsor.value)) > 0 && (
                            <div className="onboarding-payment__cell__title-secondary">
                                <p>{_node('onboarding_payment_cb_incentive', { value: coupon?.value || sponsor?.value })}</p>
                            </div>
                        )}
                    </>
                )}
                size="medium"
                className={`
                    onboarding-payment__cell
                    onboarding-payment__cell--cb
                    ${typePayment === 'cb' ? ' onboarding-payment__cell--selected' : ''}
                    `
                    .replace(/\s+/g, ' ')
                    .trim()}
                onClick={() => {
                    setTypePayment('cb');
                }}
                tabIndex={-1}
            >
                <div className="stripe-fieldset">
                    <div className="stripe-fieldset__main">
                        <StripeField
                            sType="number"
                            options={options}
                            label={_string('onboarding_field_cbNumber')}
                            onUpdate={onUpdate}
                            onChange={onStripeChange}
                        />
                        <ComplexField
                            name="customer-cardholder-name"
                            id="customer-cardholder-name"
                            rules={{
                                required: _string('error_cbName_required'),
                                maxLength: {
                                    value: 80,
                                    message: _string('error_cbName_max', { length: 255 }),
                                },
                            }}
                            label={_string('onboarding_field_cbName')}
                            controlForm={control}
                            disabled={disabled}
                            displayErrors={false}
                        />
                    </div>
                    <div className="stripe-fieldset__error-group">
                        { errNumberMess && (
                            <ErrorMessage opened>
                                {errNumberMess}
                            </ErrorMessage>
                        )}
                        { errors['customer-cardholder-name'] && (
                            <ErrorMessage opened>
                                {errors['customer-cardholder-name'].message}
                            </ErrorMessage>
                        )}
                    </div>
                    <div className="stripe-fieldset__numbers">
                        <StripeField
                            sType="exp"
                            options={options}
                            label={_string('onboarding_field_cbExp')}
                            onUpdate={onUpdate}
                            onChange={onStripeChange}
                        />
                        <StripeField
                            sType="cvc"
                            options={options}
                            label={_string('onboarding_field_cbCvc')}
                            onUpdate={onUpdate}
                            onChange={onStripeChange}
                        />
                    </div>
                    <div className="stripe-fieldset__error-group">
                        { errExpMess && (
                            <ErrorMessage opened>
                                {errExpMess}
                            </ErrorMessage>
                        )}
                        { errCvcMess && (
                            <ErrorMessage opened>
                                {errCvcMess}
                            </ErrorMessage>
                        )}
                    </div>
                </div>
                <p className="onboarding-payment__cell-info">
                    {(coupon && coupon.value) || (sponsor && sponsor.value) ? (
                        _string('onboarding_cb_hint_cb_coupon')
                    ) : (
                        _string('onboarding_cb_hint_cb')
                    )}
                </p>
            </ComplexCell>
            <ComplexCell
                title={(
                    <>
                        <div className="onboarding-payment__cell__title-main">
                            <VisualRadio
                                id="onboarding-radio-tbd"
                                selected={typePayment === 'tbd'}
                                tabIndex={-1}
                            />
                            <p className="onboarding-payment__cell__title-text">{_string('onboarding_cb_choice_later')}</p>
                        </div>
                        {((coupon && coupon.value) || (sponsor && sponsor.value)) > 0 && (
                            <div className="onboarding-payment__cell__title-secondary">
                                <p>{_string('onboarding_payment_tbd_incentive')}</p>
                            </div>
                        )}
                    </>
                )}
                size="medium"
                className={`
                    onboarding-payment__cell
                    onboarding-payment__cell--tbd
                    ${typePayment === 'tbd' ? ' onboarding-payment__cell--selected' : ''}
                    `
                    .replace(/\s+/g, ' ')
                    .trim()}
                onClick={() => {
                    setTypePayment('tbd');
                }}
                tabIndex={-1}
            >
                <p className="onboarding-payment__cell-info">
                    {(coupon || sponsor) && (
                        coupon?.name === process.env.COUPON_DEFAULT || sponsor
                            ? (_string('onboarding_cb_hint_later_default_coupon'))
                            : (_string('onboarding_cb_hint_later_coupon'))
                    )}
                    {!coupon && !sponsor && (
                        _string('onboarding_cb_hint_later')
                    )}
                </p>
            </ComplexCell>
        </OnboardingForm>
    );
};

OnboardingPayment.propTypes = {
    onSubmit: PropTypes.func,
    control: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ])),
    typePayment: PropTypes.string,
    coupon: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ])),
    sponsor: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ])),
    errors: PropTypes.objectOf(PropTypes.object),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    returnStripe: PropTypes.func,
    sendStripeError: PropTypes.func,
    setPaymentValue: PropTypes.func,
    setTypePayment: PropTypes.func,
};

OnboardingPayment.defaultProps = {
    onSubmit: () => {},
    loading: true,
    returnStripe: null,
    sendStripeError: null,
    disabled: false,
    control: null,
    errors: null,
    setPaymentValue: null,
    coupon: null,
    sponsor: null,
    typePayment: null,
    setTypePayment: null,
};

export default OnboardingPayment;
